import type {
  Auth0ProviderOptions,
  AuthorizationParams,
  IdToken,
} from "@auth0/auth0-react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { clearAuthCookies, setAuthCookies } from "@wayflyer/auth-sdk";
import { useEffect } from "react";
import { useNavigate, useRoutes } from "react-router";
import { usePostHog } from "posthog-js/react";

import { FallbackRedirect } from "./components/FallbackRedirect";
import { FullScreenLoader } from "./components/FullscreenLoader";
import { FullScreenMessage } from "./components/FullscreenMessage";
import { useNext } from "./hooks/useNext";
import { IS_DEV } from "./utils/env";

const USER_CONNECTION = "Username-Password-Authentication";
const STAFF_CONNECTION = "okta-staff";

interface LoginProps {
  /**
   * If true, log in using the staff connection.
   */
  staff?: boolean;

  /**
   * If true, show the whole signup screen immediately.
   */
  signup?: boolean;
}

interface WayflyerIdToken extends IdToken {
  connection: string;
}

function Login(props: LoginProps) {
  const {
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  const goNext = useNext();
  const navigate = useNavigate();
  const posthog = usePostHog();

  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");

  useEffect(() => {
    const authorizationParams: AuthorizationParams = {
      audience: "wayflyer",
      ph_distinct_id: posthog.get_distinct_id(),
      ph_session_id: posthog.get_session_id(),
      redirect_uri: window.location.origin + window.location.pathname,
      screen_hint: props.signup ? "signup" : undefined,
      ...(props.staff && {
        connection: STAFF_CONNECTION,
      }),
      // Invites may redirect here with an email to pre-fill the signup form.
      ...(email && {
        connection: USER_CONNECTION,
        login_hint: email, // Pre-fill the email field.
        prompt: "login", // Force a new session.
        screen_hint: "signup",
      }),
    };

    if (isLoading || error) {
      return;
    } else if (!isAuthenticated || email) {
      // `email` included here to force another login even with an existing
      // session.
      loginWithRedirect({ authorizationParams });
    } else {
      (async () => {
        const claims = (await getIdTokenClaims()) as
          | WayflyerIdToken
          | undefined;
        if (!claims) {
          throw new Error(
            "Auth0 said we were authenticated, but didn't give us a token.",
          );
        } else {
          if (!claims.connection) {
            throw new Error("Expected connection claim.");
          }
        }

        // Force a logout if the user hasn't come from the right connection,
        // i.e., Okta vs. user/pass since Auth0 won't do it for us, at least.
        //
        // Specifcally:
        //   `connection: okta-staff` will always force an Okta login but
        //   `connection: undefined` will pass any allowed connection.
        //
        // `prompt: login` can't help us here since we don't know the type of
        // connection the user is coming from at the point we'd use it.
        //
        // If a staff user is logged in and wants to switch to a customer
        // (e.g., for testing), Auth0 would not force a logout and ask them
        // to log in fresh with user/pass. So, we do it ourselves.
        if (
          (props.staff && claims.connection !== STAFF_CONNECTION) ||
          (!props.staff && claims.connection === STAFF_CONNECTION)
        ) {
          navigate({
            pathname: "/authn/logout",
            search: props.staff ? "?next=/authn/login-staff" : undefined,
          });
          return;
        }

        const tokens = await getAccessTokenSilently({
          authorizationParams,
          cacheMode: "off",
          detailedResponse: true,
        });
        setAuthCookies({
          accessToken: tokens.access_token,
          idToken: tokens.id_token,
        });

        if (!IS_DEV) {
          goNext({ defaultPath: props.staff ? "/staff/" : undefined });
        }
      })();
    }
  }, [
    email,
    error,
    getAccessTokenSilently,
    getIdTokenClaims,
    goNext,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    navigate,
    posthog,
    props.signup,
    props.staff,
  ]);

  return <FullScreenLoader />;
}

function Logout() {
  useNext();
  const { logout } = useAuth0();

  useEffect(() => {
    (async () => {
      clearAuthCookies();
      await logout({
        logoutParams: {
          returnTo: window.origin + "/authn/logged-out",
        },
      });
    })();
  }, [logout]);

  return <FullScreenLoader />;
}

function LoggedOut() {
  const goNext = useNext();

  useEffect(() => {
    goNext({ defaultPath: "/authn/login" });
  }, [goNext]);

  return <FullScreenLoader />;
}

interface ErrorRendererProps {
  children?: React.ReactNode;
}

function ErrorRenderer(props: ErrorRendererProps) {
  const { error } = useAuth0();
  return error ? (
    <FullScreenMessage message={error.message} />
  ) : (
    <>{props.children}</>
  );
}

export default function Auth0App() {
  const rendered = useRoutes([
    {
      path: "*",
      element: <FallbackRedirect to="/authn/login" />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "login-staff",
      element: <Login staff />,
    },
    {
      path: "signup",
      element: <Login signup />,
    },
    {
      path: "logout",
      element: <Logout />,
    },
    {
      path: "logged-out",
      element: <LoggedOut />,
    },
  ]);

  const auth0Props: Auth0ProviderOptions = {
    authorizationParams: {
      audience: "wayflyer",
    },
    cacheLocation: "localstorage",
    clientId: __WF_CONFIG__.clientId,
    domain: __WF_CONFIG__.domain,
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  };

  return (
    <>
      <Auth0Provider {...auth0Props}>
        <ErrorRenderer>{rendered}</ErrorRenderer>
      </Auth0Provider>
    </>
  );
}
