import Cookies from "js-cookie";
import { useEffect } from "react";
import { posthog } from "posthog-js";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { useLocation } from "react-router";
import type { CaptureResult, Properties } from "posthog-js";

import { POSTHOG_HOST } from "../utils/env";

/**
 * If a OneTrust cookie is present with performance tracking consent, then we
 * can use the default localStorage+cookie persistence. Otherwise, we use
 * memory persistence.
 */
export function getPersistence(): "localStorage+cookie" | "memory" {
  const consentRaw = Cookies.get("OptanonConsent");
  if (!consentRaw) {
    return "memory";
  }

  // OneTrust Cookies: https://my.onetrust.com/s/article/UUID-2dc719a8-4be5-8d16-1dc8-c7b4147b88e0
  const consentData = consentRaw
    .split("&")
    .reduce((acc: Record<string, string>, kv) => {
      const [k, v] = kv.split("=");
      acc[decodeURIComponent(k)] = decodeURIComponent(v);
      return acc;
    }, {});

  // Cookie Group IDs: https://my.onetrust.com/s/article/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9
  // C0002 is the ID for the "Performance" group, and 1 means they've consented.
  if (consentData["groups"]?.includes("C0002:1")) {
    return "localStorage+cookie";
  } else {
    return "memory";
  }
}

/**
 * Redact sensitive information (e.g. token code) before sending to PostHog.
 */
export function redactBeforeSend(
  event: CaptureResult | null,
): CaptureResult | null {
  if (!event) {
    return event;
  }

  function _redactProperties(properties: Properties): Properties {
    return Object.entries(properties).reduce((acc: Properties, [k, v]) => {
      if (k.includes("url") && typeof v === "string") {
        const url = new URL(v);
        if (url.searchParams.has("code")) {
          url.searchParams.set("code", "redacted");
        }

        acc[k] = url.toString();
      } else {
        acc[k] = v;
      }

      return acc;
    }, {});
  }

  event.properties = _redactProperties(event.properties);
  event.$set = event.$set ? _redactProperties(event.$set) : event.$set;
  event.$set_once = event.$set_once
    ? _redactProperties(event.$set_once)
    : event.$set_once;

  return event;
}

const searchParams = new URLSearchParams(window.location.href);
const distinctID = searchParams.get("distinct_id") ?? undefined;
const sessionID = searchParams.get("session_id") ?? undefined;

posthog.init(__WF_CONFIG__.posthogKey, {
  api_host: POSTHOG_HOST,
  autocapture: false,
  before_send: redactBeforeSend,
  capture_pageview: false,
  persistence: getPersistence(),
  bootstrap: {
    distinctID,
    sessionID,
  },
});

function PostHogInner({ children }: { children: React.ReactNode }) {
  const posthog = usePostHog();
  const location = useLocation();

  useEffect(() => {
    if (!posthog) {
      return;
    }

    posthog.register_once({ event_source: "app" });
  }, [posthog]);

  useEffect(() => {
    if (!posthog) {
      return;
    }

    posthog.capture("$pageview");
  }, [posthog, location.pathname]);

  return <>{children}</>;
}

type WfPostHogProviderProps = {
  children: React.ReactNode;
};

export function WfPostHogProvider({ children }: WfPostHogProviderProps) {
  return (
    <PostHogProvider client={posthog}>
      <PostHogInner>{children}</PostHogInner>
    </PostHogProvider>
  );
}
